import React from 'react';

import { useMediaQuery, useTheme } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';

interface AppBarProps {
  open? : boolean;
  children? : React.ReactNode;
}

function AppBar({ open, children } : AppBarProps) {
  const theme = useTheme();
  const isPrint = useMediaQuery('print');

  if (isPrint) return null;

  return (
    <MuiAppBar
      sx={{
        zIndex : theme.zIndex.drawer + 1,
        transition : theme.transitions.create(['width', 'margin'], {
          easing : theme.transitions.easing.sharp,
          duration : theme.transitions.duration.leavingScreen,
        }),
        boxSizing : 'border-box',
        ...(open && {
          marginLeft : theme.layout.drawerWidth,
          width : `calc(100% - ${theme.layout.drawerWidth}px)`,
          transition : theme.transitions.create(['width', 'margin'], {
            easing : theme.transitions.easing.sharp,
            duration : theme.transitions.duration.enteringScreen,
          })
        })
      }}
    >
      { children }
    </MuiAppBar>
  );
};

export default AppBar;
