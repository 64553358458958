import React from 'react';

import MuiTable from '@mui/material/Table';
import TableHeader from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

interface TableProps {
  id? : string;
  keys : string[];
  length : number;
  headGenerator : (key : string) => string;
  rowGenerator : (key : string, index : number) => string;
}

function Table({ id, keys, length, headGenerator, rowGenerator } : TableProps) {

  return (
    <MuiTable size='small'>
      <TableHeader>
        <TableRow
          sx={{
            borderStyle : 'solid',
            borderWidth : 0,
            borderBottomWidth : 2,
          }}
        >
          { keys.map(key => (
            <TableCell
              key={`${id}-header-${key}`}
              sx={{ borderStyle : 'none' }}
            >
              { headGenerator(key) }
            </TableCell>
          )) }
        </TableRow>
      </TableHeader>
      <TableBody>
        { Array(length).fill(null).map((_, i) => (
          <TableRow
            key={`${id}-row-${i}`}
            sx={{
              borderStyle : 'solid',
              borderWidth : 0,
              borderBottomWidth : 1,
            }}
          >
            { keys.map(key => (
              <TableCell
                key={`${id}-cell-${key}-${i}`}
                sx={{ borderStyle : 'none' }}
              >
                { rowGenerator(key, i) }
              </TableCell>
            )) }
          </TableRow>
        )) }
      </TableBody>
    </MuiTable>
  );
}

export default Table;
