import React from 'react';

declare module '@mui/material/styles' {
  interface Theme {
    layout : {
      barHeight : number;
      drawerWidth : number;
    };
  }

  interface ThemeOptions {
    layout? : {
      barHeight? : number;
      drawerWidth? : number;
    };
  }
}

declare module "@mui/material/styles/createPalette" {
  interface Palette {
    bg : {
      main : string;
    };
  }

  interface PaletteOptions {
    bg? : {
      main? : string;
    };
  }
}

export type Component = React.ElementType;
export const componets = {
  h1 : 'h1' as Component,
  h2 : 'h2' as Component,
  h3 : 'h3' as Component,
  main : 'main' as Component,
};

export type Variant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' |
  'body1' | 'body2';
export const variants = {
  h1 : 'h1' as Variant,
  h2 : 'h2' as Variant,
  h3 : 'h3' as Variant,
  h4 : 'h4' as Variant,
  h5 : 'h5' as Variant,
  h6 : 'h6' as Variant,
  body1 : 'body1' as Variant,
  body2 : 'body2' as Variant,
};

export type Demension = 'auto' | 'sm' | 'md' | 'lg' | 'full' | 'fullview';
export const demensions = {
  auto : 'auto' as Demension,
  small : 'sm' as Demension,
  medium : 'md' as Demension,
  large : 'lg' as Demension,
  full : 'full' as Demension,
  fullview : 'fullview' as Demension,
}

export type Spacing = 'dense' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | null;
export const spacings = {
  dense : 'dense' as Spacing,
  small : 'sm' as Spacing,
  medium : 'md' as Spacing,
  large : 'lg' as Spacing,
  xlarge : 'xl' as Spacing,
  xxlarge : 'xxl' as Spacing,
}

export interface Margin {
  x? : Spacing;
  y? : Spacing;
  top? : Spacing;
  left? : Spacing;
  bottom? : Spacing;
  right? : Spacing;
}

export type Color = string;
export const colors = {
  text : {
      primary : 'text.primary' as Color,
      secondary : 'text.secondary' as Color,
  },
};

export type Alignment = 'center';
export const alignments = {
    center : 'center' as Alignment,
};

export const settings = {
  componets,
  variants,
  demensions,
  spacings,
  alignments,
  colors,
}

export const convert = {
  width : (width : Demension | undefined) => {
    switch (width) {
      case demensions.small:
        return '300px';
      case demensions.medium:
        return '600px';
      case demensions.large:
        return '1200px';
      case demensions.full:
        return '100%';
      default:
        return 'auto';
    }
  },
  height : (height : Demension | undefined) => {
    switch (height) {
      case demensions.full:
        return '100%';
      case demensions.fullview:
        return '100vh';
      default:
        return 'auto';
    }
  },
  margin : (margin : Margin | undefined) => {
    function convert(spacing : Spacing | undefined) {
      switch (spacing) {
        case spacings.small:
          return '8px';
        case spacings.medium:
          return '16px';
        case spacings.large:
          return '32px';
        case spacings.xlarge:
          return '64px';
        case spacings.xxlarge:
          return '96px';
        default:
          return 'auto';
      }
    }
    return {
      top : margin?.top ? convert(margin.top) : convert(margin?.y),
      right : margin?.right ? convert(margin.right) : convert(margin?.x),
      bottom : margin?.bottom ? convert(margin.bottom) : convert(margin?.y),
      left : margin?.left ? convert(margin.left) : convert(margin?.x),
    }
  }
}
