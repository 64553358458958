import React from 'react';
import MuiLink from '@mui/material/Link';

import { Color } from './types';

interface LinkProps {
  href? : string;
  color? : Color;
  children? : React.ReactNode;
}

function Link({ href, color, children } : LinkProps) {
  return (
    <MuiLink
      color={color ? color : 'inherit'}
      href={href}
    >
      { children }
    </MuiLink>
  );
}

export default Link;
