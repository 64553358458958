import React from 'react';

import Text from '#materials/Text';
import Link from '#materials/Link';
import { variants, colors, alignments } from '#materials';

function Copyright () {
  return (
    <Text
      variant={variants.body2}
      color={colors.text.primary}
      align={alignments.center}
    >
      {'Copyright © '}
      <Link href='https://mrktbox.com/'>
        MRKTBOX
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Text>
  );
}

export default Copyright;
