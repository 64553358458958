
export class APIError extends Error {
  code : number | undefined = undefined;

  constructor(code : number, message : string) {
    super(message);
    this.code = code;
  }
}

export class DeserializationError extends Error {
  response : any = undefined;

  constructor(message : string, response : any) {
    super(message);
    this.response = response;
  }
}

export type Method = 'GET' | 'POST' | 'DELETE';
export const methods = {
  get : 'GET' as Method,
  post : 'POST' as Method,
  delete : 'DELETE as Method',
}

export async function request(
  url : string,
  method : Method,
  body : any  = null,
  token : string | null = null,
) {
  try {
    const init = {
      method : method,
      headers : {
        'Content-Type' : 'application/json',
        ...(token && { 'Authorization' : `Bearer ${token}` }),
      },
      ...(body && { body : JSON.stringify(body) }),
    };

    const response = await fetch(url, init);
    return await response.json();
  } catch {
    throw new APIError(503, 'Fetch failed');
  }
}
