import React from 'react';
import Typography from '@mui/material/Typography';

import {
  Component,
  Variant,
  Color,
  Alignment,
  Spacing,
  settings,
} from './types'

interface TextProps {
  component? : Component;
  variant? : Variant;
  color? : Color;
  align? : Alignment;
  spacing? : Spacing;
  noWrap? : boolean;
  children : React.ReactNode;
}

function Text(props : TextProps) {
  return (
    <Typography
        component={props.component ? props.component : 'span'}
        variant={props.variant}
        color={props.color ? props.color : 'inherit'}
        align={props.align}
        noWrap={props.noWrap}
        sx={{
          display : 'block',
          px : [2],
          ...(props.spacing !== settings.spacings.dense && { mb : [2] }),
        }}
    >
        { props.children }
    </Typography>
  );
}

export default Text;
