import React from 'react';

import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import { Component, Demension, convert } from './types';

interface FlexItemProps {
  component? : Component;
  width? : number;
  grow? : boolean;
  shrink? : boolean;
  overflow? : boolean;
  fill? : boolean;
  height? : Demension;
  children? : React.ReactNode;
}

function Flex({
  component = 'div',
  width,
  grow = false,
  shrink = false,
  overflow = false,
  fill = false,
  height,
  children,
} : FlexItemProps) {
  const theme = useTheme();

  return (
    <Box
      component={component}
      sx={{
        height : convert.height(height),
        ...(width && { width : [width] }),
        ...(grow && { flexGrow : 1 }),
        ...(shrink && { flexShrink : 1 }),
        overflow : overflow ? 'auto' : 'hidden',
        ...(fill && { backgroundColor : theme.palette.bg.main })
      }}
    >
      { children }
    </Box>
  );
}

export default Flex;
