import React from 'react';
import ReactDOM from 'react-dom/client';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import App from './App';
import { checkVersion } from './version';

checkVersion();

Sentry.init({
  dsn : process.env.REACT_APP_SENTRY_DSN,
  integrations : [new BrowserTracing()],
  tracesSampleRate : process.env.REACT_APP_SENTRY_SAMPLING_RATE,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
