import React, { useCallback, useState } from 'react';

import Button from '#materials/Button';
import DatePicker from '#materials/DatePicker';

interface DateSelectorProps {
  setDate : (date : Date | null) => void;
  buttonText? : string;
}

function DateSelector({ setDate, buttonText } : DateSelectorProps) {
  const [value, setValue] = useState<Date | null>(new Date());
  const [changed, setChanged] = useState(false);
  const hasButton = !!buttonText;

  const handleChange = useCallback((value : Date | null) => {
    if (hasButton) {
      setValue(value);
      setChanged(true);
    }
    else setDate(value);
  }, [hasButton, setDate, setValue]);

  const handleClick = useCallback(() => {
    setDate(value);
    setChanged(false);
  }, [value, setDate]);

  return (
    <>
      <DatePicker
        label='Report Date'
        value={value}
        setValue={handleChange}
      />
      { hasButton &&
        <Button onClick={handleClick} disabled={!changed}>
          { buttonText }
        </Button>
      }
    </>
  );
}

export default DateSelector;
