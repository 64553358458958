import React from 'react';

import { createTheme, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

interface ProviderProps {
  children : React.ReactNode;
}

function Providers({ children } : ProviderProps) {
  const defaultTheme = createTheme({
    palette : {
      primary : {
        main : '#929f4b',
      },
      bg : {
        main : '#e4ecda',
      },
    },
    layout : {
      barHeight : 60,
      drawerWidth : 240,
    },
  });

  return (
    <ThemeProvider theme={defaultTheme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        { children }
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default Providers;
