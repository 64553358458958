import React from 'react';

import AppView from '#components/dashboard/AppView';
import Workspace from '#components/dashboard/Workspace';

import AppBar from '#components/dashboard/AppBar';

interface DashboardProps {
  children? : React.ReactNode;
}

function Dashboard({ children } : DashboardProps) {
  return (
    <AppView>
      <AppBar title='MRKTBOX'>

      </AppBar>
      <Workspace>
        { children }
      </Workspace>
    </AppView>
  );
}

export default Dashboard;
