import React from 'react';

import Card from '#materials/Card';
import Text from '#materials/Text';
import { settings } from '#materials';

interface SectionProps {
  title? : string;
  text? : string;
  children : React.ReactNode;
}

function Section({ title, text, children } : SectionProps) {

  return (
    <Card>
      { title &&
        <Text component={settings.componets.h3}>
          { title }
        </Text>
      }
      { text &&
        <Text
          component={settings.componets.h3}
          variant={settings.variants.body2}
        >
          { text }
        </Text>
      }
      { children }
    </Card>
  );
}

export default Section;
