import React from 'react';

import FlexItem from '#materials/FlexItem';
import ListItem from '#materials/ListItem';
import Text from '#materials/Text';
import IconButton from '#materials/IconButton';
import Toolbar from '#materials/Toolbar';
import AppBar from '#materials/AppBar';
import { AssignmentIcon, MenuIcon, settings } from '#materials';

import SideBar from '#components/dashboard/SideBar';

interface AppBarProps {
  title? : string;
  children : React.ReactNode;
}

function Bar({ title, children } : AppBarProps) {
  const [open, setOpen] = React.useState(false);

  function toggleOpen() {
    setOpen(!open);
  }

  return (
    <>
      <AppBar open={open}>
        <Toolbar>
          <IconButton
            label='open drawer'
            hidden={open}
            onClick={toggleOpen}
          >
            <MenuIcon />
          </IconButton>
          { !open && <FlexItem width={10}/> }
          { title &&
            <Text
              component={settings.componets.h1}
              variant={settings.variants.h6}
              spacing={settings.spacings.dense}
              noWrap
            >
              { title }
            </Text>
          }
          { children }
        </Toolbar>
      </AppBar>
      <SideBar open={open} setOpen={setOpen}>
        <ListItem
          href='/orders/items'
          icon={<AssignmentIcon />}
        >
          Items Report
        </ListItem>
      </SideBar>
    </>
  );
}

export default Bar;
