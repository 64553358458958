import React from 'react';
import { Link } from 'react-router-dom'

import MuiListItem from '@mui/material/ListItem';
import MuiListItemButton from '@mui/material/ListItemButton';
import MuiListItemIcon from '@mui/material/ListItemIcon';
import MuiListItemText from '@mui/material/ListItemText';

interface ListItemProps {
  href : string;
  icon : React.ReactNode;
  children : React.ReactNode;
}

interface ItemProps {
  children : React.ReactNode;
}

function ListItem({ href, icon, children } : ListItemProps) {

  function ItemWrapper({ children } : ItemProps) {
    if (href) {
      return (
        <MuiListItemButton LinkComponent={Link} href={href}>
          { children }
        </MuiListItemButton>
      )
    } else {
      return (
        <MuiListItem>
          { children }
        </MuiListItem>
      )
    }
  }

  return (
    <ItemWrapper>
      { icon &&
        <MuiListItemIcon>
          { icon }
        </MuiListItemIcon>
      }
      <MuiListItemText>
        { children }
      </MuiListItemText>
    </ItemWrapper>
  )
}

export default ListItem;
