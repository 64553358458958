import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import Dashboard from '#layouts/Dashboard';
import Items from 'pages/orders/Items';

import Providers from 'Providers';
import { checkCache } from './version';
import './App.css';

function App() {
  useEffect(() => { checkCache(); }, [])

  return (
    <div className='App'>
      <Providers>
        <Router>
          <Dashboard>
            <Routes>
              <Route path='/orders/items' element={<Items />}/>
              <Route path='/' element={<></>}/>
            </Routes>
          </Dashboard>
        </Router>
      </Providers>
    </div>
  );
}

export default App;
