import { useCallback } from "react";

import { APIError } from '#api';

function useAPI() {

  const processRequest = useCallback(
    async <TIn, TOut>(
      request : (input : TIn) => TOut,
      input : TIn,
    ) => {
      try {
        return await request(input);
      } catch (e) {
        if (e instanceof APIError) {
          console.error(e.code, e.message);
          return null;
        } else throw e;
      }
    },
    [],
  )

  return {
    processRequest,
  };
}

export default useAPI;
