import React from 'react';
import MuiList from '@mui/material/List';

interface ListProps {
  children : React.ReactNode
}

function List({ children } : ListProps) {
  return (
    <MuiList>
      { children }
    </MuiList>
  )
}

export default List;
