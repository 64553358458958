import React from 'react';
import MuiButton from '@mui/material/Button';

interface ButtonProps {
  onClick? : () => void;
  disabled? : boolean;
  children? : React.ReactNode;
}

function Button({ onClick, disabled = false, children } : ButtonProps) {
  return (
    <MuiButton
      variant='contained'
      onClick={onClick}
      disabled={disabled}
      sx={{
        mx : [1],
        my : [(8 - 4.5) / 2],
      }}
    >
      { children }
    </MuiButton>
  )
}

export default Button;
