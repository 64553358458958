import React from 'react';

import ItemsReport from '#components/orders/ItemsReport';
import useMediaQuery from '#hooks/useMediaQuery';

function ItemsPage() {
  const { isPrint } = useMediaQuery();

  return (
    <ItemsReport showHead={!isPrint}/>
  );
}

export default ItemsPage;
