import { APIError, Method, request as baseRequest } from '#api';

export function getUrl(path : string) {
  return `${process.env.REACT_APP_MRKTBOX_DOMAIN}/${path}`;
}

export function formatDate(date : Date) {
  return date.toISOString().split('T')[0];
}

export function parseDate(date : string) {
  return ;
}

export async function request(
  url : string,
  method : Method,
  body : any  = null,
  token : string | null = null,
) {
  const response = await baseRequest(
    url,
    method,
    body,
    token,
  );

  if (response.error) {
    throw new APIError(
      response.error.code,
      response.error.message,
    );
  }

  return response;
}
