
type Format = 'numeric' | 'descript';
export const formats = {
  numeric : 'numeric' as Format,
  descript : 'descript' as Format,
};

export function formatDate(date : Date, format : Format = formats.numeric) {
  switch (format) {
    case formats.numeric:
      return date.toISOString().split('T')[0];
    case formats.descript:
      const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      } as const;
      return date.toLocaleDateString("en-US", options);
    default:
      return date.toISOString().split('T')[0];
  }
}
