import React from 'react';

import { useMediaQuery } from '@mui/material';
import Paper from '@mui/material/Paper';

interface CardProps {
  children : React.ReactNode;
}

function Card({ children } : CardProps) {
  const isPrint = useMediaQuery('print');

  return (
    <Paper
      elevation={isPrint ? 0 : 1}
      sx={{
        my : 2,
        p : 2,
      }}
    >
      { children }
    </Paper>
  );
}

export default Card;
