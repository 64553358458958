import { useMediaQuery as useMuiMediaQuery } from '@mui/material';

function useMediaQuery() {
  const isPrint = useMuiMediaQuery('print')

  return {
    isPrint,
  }
}

export default useMediaQuery;
