import React from 'react';

import { useTheme } from '@mui/material';
import MuiToolbar from '@mui/material/Toolbar';

interface ToolbarProps {
  height? : number;
  justify? : 'flex-start' | 'flex-end';
  children? : React.ReactNode;
}

function Toolbar({
  justify = 'flex-start',
  children,
} : ToolbarProps) {
  const theme = useTheme()

  return (
    <MuiToolbar
      sx={{
        display : 'flex',
        height : theme.layout.barHeight,
        px : [0],
        alignItems : 'center',
        justifyContent : justify,
      }}
    >
      { children }
    </MuiToolbar>
  );
}

export default Toolbar;
