import React from 'react';

import MaterialProviders from '#materials/Provider';

interface ProviderProps {
  children? : React.ReactNode;
}

function Providers({ children } : ProviderProps) {
  return (
    <MaterialProviders>
        { children }
    </MaterialProviders>
  );
}

export default Providers;
