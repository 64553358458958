import React from 'react';

import Drawer from '#materials/Drawer';
import Divider from '#materials/Divider';
import List from '#materials/List';
import Toolbar from '#materials/Toolbar';
import IconButton from '#materials/IconButton';
import { ChevronLeftIcon } from '#materials';

interface SideBarProps {
  open : boolean;
  setOpen : (open : boolean) => void;
  children? : React.ReactNode;
}

function SideBar({ open, setOpen, children } : SideBarProps) {

  function toggleOpen() {
    setOpen(!open);
  }

  return (
    <Drawer open={open}>
      <Toolbar justify='flex-end'>
        <IconButton
          label='close drawer'
          onClick={toggleOpen}
          hidden={!open}
        >
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <Divider />
      <List>
        { children }
      </List>
    </Drawer>
  );
}

export default SideBar;
