import { useCallback } from 'react';

import { retrieveItems as retrieveItemsAPI } from '#mrktbox/orders';
import useAPI from '#hooks/api/useAPI';

function useOrders() {
  const { processRequest } = useAPI();

  const retrieveItems = useCallback( async (date : Date) => {
    return await processRequest(
      retrieveItemsAPI,
      { date },
    )
  }, [processRequest])

  return {
    retrieveItems,
  };
}

export default useOrders;
