import React from 'react';

import Box from '@mui/material/Box';

interface FlexProps {
  children : React.ReactNode;
}

function Flex({ children } : FlexProps) {
  return (
    <Box
      sx={{
        display : 'flex',
      }}
    >
      { children }
    </Box>
  );
}

export default Flex;
