import React, { useCallback, useEffect, useState } from 'react';

import dayjs, { Dayjs } from 'dayjs';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker'

interface DatePickerProps {
  label : string;
  value : Date | null;
  setValue : (value : Date | null) => void;
}

function DatePicker({ label, value, setValue } : DatePickerProps) {
  const [date, setDate] = useState(dayjs(value));

  const handleChange = useCallback((value : Dayjs | null) => {
    if (!value) {
      setValue(null);
    } else {
      setValue(value.toDate());
    }
  }, [setValue]);

  useEffect(() => {
    setDate(dayjs(value));
  }, [value, setDate]);

  return (
    <MuiDatePicker
      label={label}
      value={date}
      onChange={handleChange}
      sx={{
        mx : [1],
        my : [(8 - 7) / 2],
      }}
    />
  )
}

export default DatePicker;
