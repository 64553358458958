import React from 'react';

import Flex from '#materials/Flex';

interface AppViewProps {
  children? : React.ReactNode;
}

function AppView({ children } : AppViewProps) {
  return (
    <Flex>
      { children }
    </Flex>
  )
}

export default AppView;
