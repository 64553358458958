import React from 'react';

import { useMediaQuery, useTheme } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';

interface DrawerProps {
  open : boolean;
  children : React.ReactNode;
}

function Drawer({ open, children } : DrawerProps) {
  const theme = useTheme();
  const isPrint = useMediaQuery('print');

  if (isPrint) return null;

  return (
    <aside>
      <MuiDrawer
        variant='permanent'
        open={open}
        sx={{
          height : '100%',
        }}
        PaperProps={{
          sx : {
            position : 'relative',
            whiteSpace : 'nowrap',
            width : theme.layout.drawerWidth,
            transition : theme.transitions.create('width', {
              easing : theme.transitions.easing.sharp,
              duration : theme.transitions.duration.enteringScreen,
            }),
            backgroundColor : theme.palette.bg.main,
            ...(!open && {
              overflowX : 'hidden',
              transition : theme.transitions.create('width', {
                easing : theme.transitions.easing.sharp,
                duration : theme.transitions.duration.leavingScreen,
              }),
              width : theme.spacing(7),
            })
          }
        }}
      >
        { children }
      </MuiDrawer>
    </aside>
  );
}

export default Drawer;
