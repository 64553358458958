import React from 'react';

import Container from '@mui/material/Container';
import { Demension, Margin, convert } from './types';

interface CenterProps {
  width? : Demension;
  maxWidth? : Demension;
  margin? : Margin;
  children : React.ReactNode;
}

function Center({
  width,
  maxWidth,
  margin,
  children,
} : CenterProps) {
  return (
    <Container
    sx={{
      ...(width && { width : convert.width(width) }),
      ...(maxWidth && { maxWidth : convert.width(maxWidth) }),
      ...(margin && {
          mt : convert.margin(margin).top,
          mr : convert.margin(margin).right,
          mb : convert.margin(margin).bottom,
          ml : convert.margin(margin).left,
        }),
      }}
    >
      { children }
    </Container>
  );
}

export default Center;
