import { ItemsList } from '#types';

import { APIError, DeserializationError, methods } from '#api';
import { formatDate, getUrl, request } from '#mrktbox';

const ORDERS_PATH = 'orders';
const ITEMS_PATH = `${ORDERS_PATH}/items/`;

export async function retrieveItems(payload : { date : Date }) {
  const response =  await request(
    getUrl(ITEMS_PATH),
    methods.post,
    {
      store_id : process.env.REACT_APP_DEFAULT_STORE_ID,
      date : formatDate(payload.date),
    },
  );
  if (!response) throw new APIError(503, 'No response');

  try {
    const items = {} as ItemsList;
    for (const productId in response.items) {
      const item = response.items[productId];
      items[parseInt(productId)] = {
        name : item.name,
        quantity : item.quantity,
        categoryName : item.category_name,
      };
    }
    return items;
  } catch {
    throw new DeserializationError(
      'Could not deserialize item list',
      response,
    );
  }
}
