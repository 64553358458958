import React, { useEffect, useState } from 'react';

import { LineItem } from '#types';

import Table from '#materials/Table';
import Section from '#components/dashboard/Section';
import DateSelector from '#components/forms/DateSelector';

import useOrders from '#hooks/api/useOrders';
import { formats, formatDate } from '#utils/date';

interface ItemsReportProps {
  showHead? : boolean;
}

function ItemsReport({ showHead = true } : ItemsReportProps) {
  const { retrieveItems } = useOrders();

  const [date, setDate] = useState<Date | null>(new Date());
  const [items, setItems] = useState<LineItem[]>([])

  useEffect(() => {
    async function getItems() {
      if (!date) return;

      const itemList = (await retrieveItems(date));
      if (!itemList) {
        setItems([]);
        return;
      }

      const list = [] as LineItem[];
      for (const productId in itemList) list.push(itemList[productId]);
      const sortedList = list.sort(
        (a, b) => a.categoryName > b.categoryName ? 1 : -1
      );
      setItems(sortedList);
    }
    getItems();
  }, [date, setItems, retrieveItems]);

  const tableKeys = [
    'product',
    'qty',
    'category',
  ];
  const tableLength = items ? Object.keys(items).length : 0;

  const tableText = tableLength && date
    ? `${formatDate(date, formats.descript)}`
    : 'No items found';

  function headGenerator(key : string) {
    switch (key) {
      case 'product': return 'Product';
      case 'qty': return 'Qty';
      case 'category': return 'Category';
      default: return '';
    }
  }

  function rowGenerator(key : string, index : number) {
    const item = items[index];
    switch (key) {
      case 'product': return item.name;
      case 'qty': return `${item.quantity}`;
      case 'category': return item.categoryName;
      default: return '';
    }
  }

  return (
    <>
      { showHead &&
        <Section
          title='Generate a Daily Item Report'
          text='Select a date and click "Generate" to create an item report.'
        >
          <DateSelector
            setDate={setDate}
            buttonText='Generate'
          />
        </Section>
      }
      { date &&
        <Section
          title='Packing List'
          text={tableText}
        >
          { !!tableLength &&
            <Table
              keys={tableKeys}
              length={tableLength}
              headGenerator={headGenerator}
              rowGenerator={rowGenerator}
            />
          }
        </Section>
      }
    </>
  );
}

export default ItemsReport;
