import React from 'react';
import MuiIconButton from '@mui/material/IconButton';

interface IconButtonProps {
  label : string;
  hidden? : boolean;
  onClick? : React.MouseEventHandler<HTMLButtonElement>;
  children? : React.ReactNode;
}

function IconButton({
  label,
  hidden = false,
  onClick,
  children,
} : IconButtonProps) {
  return (
    <MuiIconButton
      edge='start'
      color='inherit'
      aria-label={label}
      onClick={onClick}
      sx={{
        mx : 1,
        ...(hidden && { display: 'none' }),
      }}
    >
      { children }
    </MuiIconButton>
  )
}

export default IconButton;
