import React from 'react';

import Center from '#materials/Center';
import FlexItem from '#materials/FlexItem';
import Copyright from '#components/dashboard/Copyright';
import { settings } from '#materials';

interface WorkspaceProps {
  children? : React.ReactNode;
}

function Workspace({ children } : WorkspaceProps) {
  return (
    <FlexItem
      component={settings.componets.main}
      grow
      overflow
      fill
      height={settings.demensions.fullview}
    >
      <Center
        maxWidth={settings.demensions.large}
        margin={{
          top : settings.spacings.xxlarge,
        }}
      >
        { children }
        <Copyright />
      </Center>
    </FlexItem>
  )
}

export default Workspace;
